import { Fragment, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Radio, RadioGroup } from '@sezane/front-components';
import { useQuery } from '@tanstack/react-query';

import Loader from '@components/Loader/Loader';
import { AdditionalInformations } from '@pages/ReturnsPage/AdditionalInformations';
import apiOms from '@utils/apiOms';
import Step from './Step';
import { type FormValues, getCreateReturnSteps } from './utils';
import type { OMS } from 'types';

export const refundOptionsMessages = defineMessages({
    card: { id: 'return.step_refund.refund_mode.card' },
    credits: { id: 'return.step_refund.refund_mode.credits' },
});

interface StepRefundProps {
    onChange: () => void;
}

const StepRefund = ({ onChange }: StepRefundProps) => {
    const { control, setValue, watch } = useFormContext<FormValues>();
    const selectedOrder = watch('order');
    const refundOption = watch('refundOption');

    // handle initial value selection after api response
    const [refresh, setRefresh] = useState(Date.now());

    const { data: refundOptionsResponse, isFetching: isLoadingRefundOptions } = useQuery(
        ['getRefundOptions', selectedOrder?.number],
        () => apiOms.omsAuthenticated.getRefundOptions(selectedOrder?.number!)
    );
    const refundOptions = refundOptionsResponse?.data;

    useEffect(() => {
        if (refundOptions?.availableRefundModes?.length === 1) {
            setValue('refundOption', refundOptions.availableRefundModes[0] as OMS.OrderReturnWrite['refundOption']);
            setRefresh(Date.now());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refundOptions]);

    return (
        <Step
            id={getCreateReturnSteps()[2]}
            className="mt-24 pt-6"
            title={<FormattedMessage id="return.step_refund.title" />}
        >
            {isLoadingRefundOptions ? (
                <Loader className="mt-10" />
            ) : (
                <>
                    <div className="mt-10 flex">
                        <Controller
                            control={control}
                            name="refundOption"
                            render={({ field }) => (
                                <RadioGroup
                                    {...field}
                                    key={refresh}
                                    onChange={value => {
                                        field.onChange(value);
                                        onChange();
                                    }}
                                >
                                    {refundOptions?.availableRefundModes?.map(mode => (
                                        <Fragment key={mode}>
                                            <Radio className="gap-6 px-6 py-2 text-black" value={mode}>
                                                <div className="text-black">
                                                    <FormattedMessage id={refundOptionsMessages[mode].id} />
                                                </div>
                                            </Radio>
                                        </Fragment>
                                    ))}
                                </RadioGroup>
                            )}
                        />
                    </div>

                    {refundOptions?.additionalInformations && (
                        <AdditionalInformations
                            className="mt-10"
                            additionalInformations={refundOptions.additionalInformations}
                            refundOption={refundOption}
                        />
                    )}
                </>
            )}
        </Step>
    );
};

export default StepRefund;
