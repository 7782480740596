import { defineMessages, FormattedMessage } from 'react-intl';
import { Navigate, useLocation } from 'react-router-dom';

import { PageHeader, SubTitle } from '@components/commons';
import LinkButton from '@components/Link/LinkButton';
import { contactIconMessages, useContactData } from '@hooks/useContact';

const messages = defineMessages({ title: { id: 'orderReturn.return.defect.title' } });

const DefectReturn = () => {
    const contactData = useContactData();
    const location = useLocation();

    // Forbid access from url
    if (!location.state?.fromApp) return <Navigate to="/" replace={true} />;

    return (
        <section className="container col-span-full mb-20 flex max-w-7xl flex-col gap-[1.6rem] text-center mobile:mb-12">
            <PageHeader className="mb-0 text-5xl mobile:mb-4 mobile:text-3xl" title={messages.title.id}>
                <FormattedMessage id="orderReturn.return.defect.return_demand" />
            </PageHeader>
            <SubTitle className="text-5xl font-normal normal-case">
                <FormattedMessage id="orderReturn.return.defect.sorry" />
            </SubTitle>
            <p>
                <FormattedMessage
                    id="orderReturn.return.defect.description"
                    values={{ strong: chunks => <strong>{chunks}</strong> }}
                />
            </p>
            <div className="mt-12 flex w-full flex-row justify-center gap-8 mobile:mt-8 mobile:flex-col">
                {contactData
                    .filter(({ id }) => id === 'email' || id === 'whatsapp')
                    .reverse()
                    .map(({ id, icon, link }) => (
                        <div key={id} className="grow border border-gray-lighter p-8 text-center text-gray-medium">
                            {icon}
                            <div className="mb-6 font-account-heading text-sm uppercase"></div>
                            <LinkButton fullWidth className="flex flex-col py-3" href={link} target="_blank">
                                <span>
                                    <FormattedMessage id="contact.links.cta" />
                                </span>
                                <span>
                                    <FormattedMessage
                                        id={contactIconMessages[id as keyof typeof contactIconMessages].id}
                                    />
                                </span>
                            </LinkButton>
                        </div>
                    ))}
            </div>
        </section>
    );
};

export default DefectReturn;
