import clsx from 'clsx';

import AdditionnalInfoReturnBox from '@components/Box/Returns/additionalInfos/AdditionnalInfoReturnBox';
import type { OMS } from 'types';

interface AdditionalInformationsProps {
    additionalInformations?: OMS.OrderAdditionalInformation[];
    className?: string;
    refundOption?: OMS.OrderReturn['refundOption'];
}

export const AdditionalInformations = ({
    additionalInformations = [],
    className,
    refundOption,
}: AdditionalInformationsProps) => {
    return additionalInformations.length ? (
        <div className={clsx('flex flex-col gap-[1.6rem]', className)}>
            {additionalInformations?.map(({ paymentMethodName, subtitle, title }) => {
                if (paymentMethodName === 'credits' && refundOption !== 'card') return null;
                return (
                    <AdditionnalInfoReturnBox key={paymentMethodName} title={title?.translationValue}>
                        {subtitle?.translationValue}
                    </AdditionnalInfoReturnBox>
                );
            })}
        </div>
    ) : null;
};
