import { Outlet } from 'react-router';
import { ErrorBoundary } from '@sezane/front-components';

import Menu from '@components/Menu/Menu';
import { useAuth } from '@context/AuthContext';

interface SidebarLayoutProps {
    isSimplified?: boolean;
}

const SidebarLayout = ({ isSimplified }: SidebarLayoutProps) => {
    const { isAuthenticating } = useAuth();

    return isSimplified ? (
        <>
            <main className="col-span-12">{isAuthenticating ? null : <Outlet />}</main>
        </>
    ) : (
        <>
            <aside className="col-span-3 mobile:hidden">{isAuthenticating ? null : <Menu />}</aside>
            <main className="col-span-9 mobile:col-span-12 mobile:py-4 desktop:pl-16">
                <ErrorBoundary>{isAuthenticating ? null : <Outlet />}</ErrorBoundary>
            </main>
        </>
    );
};

export default SidebarLayout;
