import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import ErrorBoundary from '@components/ErrorBoundary';
import NewsletterSignup from '@components/Modal/NewsletterSignup';
import { useAppContext } from '@context/AppContext';
import { useCountry } from '@context/CountryContext';
import { useUrl } from '@hooks/useUrl';
import AlertsPage from '@pages/AlertsPage/AlertsPage';
import CarePage from '@pages/CarePage/CarePage';
import ContactPage from '@pages/ContactPage/ContactPage';
import CreditsPage from '@pages/CreditsPage/CreditsPage';
import DeleteAccountPage from '@pages/DeleteAccountPage/DeleteAccountPage';
import InfoPage from '@pages/InfoPage/InfoPage';
import OrderDetails from '@pages/OrdersPage/OrderDetails';
import OrdersPage from '@pages/OrdersPage/OrdersPage';
import PaymentPage from '@pages/PaymentPage/PaymentPage';
import ReturnCreatePage from '@pages/ReturnCreatePage/ReturnCreatePage';
import DefectReturn from '@pages/ReturnsPage/Defect';
import OversizedReturn from '@pages/ReturnsPage/Oversized';
import ReturnDetails from '@pages/ReturnsPage/ReturnDetails';
import ReturnsPage from '@pages/ReturnsPage/ReturnsPage';
import WishlistsPage from '@pages/WishlistsPage/WishlistsPage';
import { BRANDS, getBrand } from '@utils/brand';
import { DEFAULT_SITECODE } from '@utils/common';
import { octobreCSS, replaceToMultilangUrl, sezaneCSS } from '@utils/main';
import Layout from './Layout';
import SidebarLayout from './SidebarLayout';

const MainContainer = () => {
    const brand = getBrand();
    const { routes, urls } = useUrl();
    const { locale, site } = useAppContext();
    const { country } = useCountry();

    const router = createBrowserRouter(
        [
            {
                element: <Layout />,
                children: [
                    {
                        element: <SidebarLayout />,
                        children: [
                            { path: '/', element: <Navigate to={routes.orders} replace /> },
                            { path: routes.orders, element: <OrdersPage /> },
                            {
                                path: `${routes.orders}/detail/:orderId`,
                                element: <OrderDetails />,
                            },
                            { path: routes.returns, element: <ReturnsPage /> },
                            {
                                path: `${routes.returns}/detail/:returnUid`,
                                element: <ReturnDetails />,
                            },
                            { path: routes.wishlists, element: <WishlistsPage /> },
                            { path: routes.alerts, element: <AlertsPage /> },
                            { path: routes.care, element: <CarePage /> },
                            { path: routes.info, element: <InfoPage /> },
                            { path: routes.credits, element: <CreditsPage /> },
                            { path: routes.payment, element: <PaymentPage /> },
                            { path: routes.contact, element: <ContactPage /> },
                            { path: routes.deleteAccount, element: <DeleteAccountPage /> },
                        ],
                    },
                ],
                errorElement: <ErrorBoundary />,
            },
            {
                element: <Layout isSimplified />,
                children: [
                    {
                        element: <SidebarLayout isSimplified />,
                        children: [
                            { path: routes.returnCreate, element: <ReturnCreatePage /> },
                            { path: routes.returnDefect, element: <DefectReturn /> },
                            { path: routes.returnOversized, element: <OversizedReturn /> },
                        ],
                    },
                ],
                errorElement: <ErrorBoundary />,
            },
            {
                path: '*',
                element: <Navigate to="/" replace />,
            },
        ],
        {
            basename: urls.account,
        }
    );

    useEffect(() => {
        if (replaceToMultilangUrl(site.code || DEFAULT_SITECODE)) {
            router.navigate(urls.account, { replace: true });
        }
    }, [router, site.code, urls.account]);

    return (
        <>
            <Helmet
                htmlAttributes={{
                    lang: country.countryISO
                        ? `${locale}-${country.countryISO}`
                        : `${site.defaultLocale?.code}-${site.defaultCountryCode}`,
                }}
            >
                <style type="text/css">{brand === BRANDS.sezane ? sezaneCSS : octobreCSS}</style>
            </Helmet>
            <NewsletterSignup />
            <RouterProvider router={router} />
        </>
    );
};

export default MainContainer;
